/* eslint-disable camelcase */
import { get, lowerCase, uniq, compact, concat } from 'lodash';
import DatadogHandler from 'utils/datadog';
import {
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT,
  MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS,
  centreSerivce,
  classOpsService,
  classService,
  schoolService,
  userService,
  childService,
} from '../../../utils/msEndpointConstants';
import {
  retrieveListOrObject,
  retrieveObjectMapper,
  retrieveSortForMS,
} from '../../../utils';
import { DEFAULT_ERROR } from '../../../utils/constants';

const {
  GET_EVENTS,
  GET_EVENT_TYPES,
  GET_EVENT_BY_ID,
  CREATE_EVENT,
  UPDATE_EVENT,
  CANCEL_EVENT,
  GET_EVENT_PHOTO_ALBLUM_UPLOAD_URL,
  GET_EVENT_UPLOAD_URL_FOR_SCHOOL,
  GET_DRAFT_EVENTS,
  CREATE_DRAFT_EVENT,
  DELETE_DRAFT_EVENT,
  SUBMIT_DRAFT_EVENT,
  EXPORT_RSVP_LIST,
  GET_EVENT_PHOTOS,
  ADD_EVENT_PHOTOS,
  UPDATE_EVENT_PHOTOS_INDEX,
  DELETE_EVENT_PHOTOS,
  GET_EVENT_SCOPES_BY_IDS,
  GET_RSVP_COUNT_DETAILS_BY_IDS,
  PUBLISH_EVENT,
  GET_EVENT_RSVPS,
  REMOVE_CHILD_FROM_RSVP_LIST,
  REMOVE_RSVP_GUESTS,
  ADD_EVENT_RSVP,
} = MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS.EVENT;
const { GET_CENTRE_BY_IDS } = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.CENTRE;
const { GET_CLASS_BY_IDS } = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.CLASS;
const { GET_LEVEL_BY_IDS } = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.SCHOOL;
const { GET_STAFF_USER_BY_USER_IDS, GET_BY_IDS } =
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.USER;
const { GET_CHILD_BY_IDS, GET_CHILD_CLASSES_BY_CHILD_IDS } =
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.CHILD;

export const transformEventResponse = (
  res,
  eventScopes = [],
  centres = [],
  classes = [],
  levels = [],
  staffUsers = [],
  rsvpCounts = {}
) => {
  const data = get(res, 'data', {});
  return {
    totalCount: get(data, 'totalCount', 0),
    data: get(data, 'data', []).map(dt => ({
      ...retrieveObjectMapper(dt, [
        'ID:id',
        'fkCreator:fk_creator',
        'fkApprover:fk_approver',
        'title',
        'description',
        'location',
        'eventImageKey:event_image_key',
        'eventImageKeyPresignedURL:event_image_key_presigned_url',
        'eventType:type',
        'from',
        'to',
        'guestCountLimit:guest_count_limit',
        'parentConsentRequired:parent_consent_required',
        'status:status_string',
      ]),
      attendeesCount: {
        CountMapping: rsvpCounts[dt.id] || {},
      },
      eventScopes: {
        data: eventScopes
          ?.filter(({ fk_event }) => fk_event === dt.id)
          .map(scope => ({
            centre: centres
              .filter(({ id }) => scope.fk_centre === id)
              .map(centre => retrieveObjectMapper(centre, ['label']))?.[0],
            class: classes
              .filter(({ id }) => scope.fk_class === id)
              .map(clazz => ({
                ...retrieveObjectMapper(clazz, ['label']),
                level: levels
                  .filter(({ id }) => clazz.fk_level === id)
                  .map(level => ({
                    ...retrieveObjectMapper(level, ['label']),
                  }))?.[0],
              }))?.[0],
            // school
          })),
      },
      userByFkCreator: staffUsers
        ?.filter(({ user }) => user.id === dt.fk_creator)
        .map(({ user }) =>
          retrieveObjectMapper(user, ['firstname', 'lastname'])
        )?.[0],
      creator: staffUsers
        ?.filter(({ user }) => user.id === dt.fk_creator)
        .map(({ staff, user }) => ({
          displayName: staff?.display_name,
          user: retrieveObjectMapper(user, ['firstname', 'lastname']),
        }))?.[0],
    })),
  };
};

export const msFetchEventListing = async (_, reqBody = {}) => {
  const { reqData, dispatchEvents = () => {}, dispatch = () => {} } = reqBody;
  try {
    const reqDatas = reqData;
    const _classOpsService = classOpsService();

    const fullApiUrl = status => {
      const queryParams = {
        page: get(reqDatas, 'pagination.page', 1),
        perPage: get(reqDatas, 'pagination.perPage', 10),
        // ...retrieveSortForMS(get(reqDatas, 'pagination.sort')),
      };
      if (status === 'draft') {
        return `${GET_DRAFT_EVENTS}?${new URLSearchParams(
          queryParams
        ).toString()}`;
      }
      queryParams.category = status;
      return `${GET_EVENTS}?${new URLSearchParams(queryParams).toString()}`;
    };
    const [pendingEvents, upcomingEvents, pastEvents, draftEvents] =
      await Promise.all([
        await _classOpsService.get(fullApiUrl('pending')),
        await _classOpsService.get(fullApiUrl('upcoming')),
        await _classOpsService.get(fullApiUrl('past')),
        await _classOpsService.get(fullApiUrl('draft')),
      ]);

    const allEvents = [
      ...(pendingEvents?.data?.data || []),
      ...(upcomingEvents?.data?.data || []),
      ...(pastEvents?.data?.data || []),
      ...(draftEvents?.data?.data || []),
    ];
    const eventIds = uniq(allEvents.map(({ id }) => id));
    const [eventScopes, rsvpCountDetails] = await Promise.all([
      eventIds.length > 0
        ? _classOpsService
            .get(GET_EVENT_SCOPES_BY_IDS, { eventIds: eventIds.join(',') })
            .then(retrieveListOrObject)
        : Promise.resolve([]),
      eventIds.length > 0
        ? _classOpsService
            .get(GET_RSVP_COUNT_DETAILS_BY_IDS, {
              eventIds: eventIds.join(','),
            })
            .then(res => res.data || {})
        : Promise.resolve({}),
    ]);

    const centreIds = uniq(
      eventScopes
        ?.map?.(({ fk_centre }) => fk_centre)
        ?.filter?.(fk_centre => fk_centre)
    );
    const classIds = uniq(
      eventScopes
        ?.map?.(({ fk_class }) => fk_class)
        ?.filter?.(fk_class => fk_class)
    );
    const userIds = uniq(
      allEvents
        ?.map?.(({ fk_creator }) => fk_creator)
        ?.filter?.(fk_creator => fk_creator)
    );
    const [centres, classes, staffUsers] = await Promise.all([
      centreIds.length > 0
        ? await centreSerivce()
            .post(...GET_CENTRE_BY_IDS(centreIds))
            .then(retrieveListOrObject)
        : Promise.resolve([]),
      classIds.length > 0
        ? await classService()
            .post(...GET_CLASS_BY_IDS(classIds))
            .then(retrieveListOrObject)
        : Promise.resolve([]),
      userIds.length > 0
        ? await userService()
            .post(...GET_STAFF_USER_BY_USER_IDS(userIds))
            .then(response => {
              return Object.entries(response?.data?.data).map(
                entry => entry[1]?.data?.[0]
              );
            })
        : Promise.resolve([]),
    ]);
    const levelIds = uniq(
      classes.map(({ fk_level }) => fk_level).filter(fk_level => fk_level)
    );
    let levels = [];
    if (levelIds.length > 0) {
      levels = await schoolService()
        .post(...GET_LEVEL_BY_IDS(levelIds))
        .then(retrieveListOrObject);
    }
    const pendingEventResp = transformEventResponse(
      pendingEvents,
      eventScopes,
      centres,
      classes,
      levels,
      staffUsers,
      rsvpCountDetails
    );
    const upcomingEventResp = transformEventResponse(
      upcomingEvents,
      eventScopes,
      centres,
      classes,
      levels,
      staffUsers,
      rsvpCountDetails
    );
    const pastEventResp = transformEventResponse(
      pastEvents,
      eventScopes,
      centres,
      classes,
      levels,
      staffUsers,
      rsvpCountDetails
    );
    const draftEventResp = transformEventResponse(
      draftEvents,
      eventScopes,
      centres,
      classes,
      levels,
      staffUsers,
      rsvpCountDetails
    );

    const result = {
      getDraftEvents: {
        ...draftEventResp,
      },
      getPastEvents: {
        ...pastEventResp,
      },
      getUpcomingEvents: {
        ...upcomingEventResp,
      },
      getPendingEvents: {
        ...pendingEventResp,
      },
    };
    dispatchEvents(dispatch, false, result);
    return result;
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return dispatchEvents(dispatch, false, null, { error: e.message });
  }
};

export const transformEventByIdResponse = (
  res,
  eventScopes = [],
  centres = [],
  classes = [],
  levels = [],
  staffUsers = [],
  photos = []
) => {
  const data = get(res, 'data', {});
  return {
    eventById: {
      ID: get(data, 'id'),
      fkCreator: get(data, 'fk_creator'),
      fkApprover: get(data, 'fk_approver'),
      status: get(data, 'status_string'),
      title: get(data, 'title'),
      description: get(data, 'title'),
      eventType: get(data, 'type'),
      from: get(data, 'from'),
      to: get(data, 'to'),
      publishDate: get(data, 'publish_date'),
      cancellationDate: get(data, 'cancellation_date'),
      rsvpDeadline: get(data, 'rsvp_deadline'),
      eventImageKey: get(data, 'event_image_key'),
      eventImageKeyPresignedURL: get(data, 'event_image_key_presigned_url'),
      parentConsentRequired: get(data, 'parent_consent_required'),
      termsAndConditions: get(data, 'terms_and_conditions'),
      paymentRequired: get(data, 'payment_required'),
      childEventFee: get(data, 'child_event_fee'),
      guestEventFee: get(data, 'guest_event_fee'),
      guestsAllowed: get(data, 'guests_allowed'),
      guestsDetailsRequired: get(data, 'guests_details_required'),
      guestCountLimit: get(data, 'guest_count_limit'),
      location: get(data, 'location'),
      eventScopes: {
        data: eventScopes
          ?.filter(({ fk_event }) => fk_event === data.id)
          .map(scope => ({
            ...retrieveObjectMapper(scope, [
              'fkEvent:fk_event',
              'fkSchool:fk_school',
              'fkCentre:fk_centre',
              'fkClass:fk_class',
            ]),
            centre: centres
              .filter(({ id }) => scope.fk_centre === id)
              .map(centre =>
                retrieveObjectMapper(centre, ['ID:id', 'label'])
              )?.[0],
            class: classes
              .filter(({ id }) => scope.fk_class === id)
              .map(clazz => ({
                ...retrieveObjectMapper(clazz, [
                  'ID:id',
                  'label',
                  'fkLevel:fk_level',
                ]),
                level: levels
                  .filter(({ id }) => clazz.fk_level === id)
                  .map(level => ({
                    ...retrieveObjectMapper(level, ['ID:id', 'label']),
                  }))?.[0],
              }))?.[0],
            // school
          })),
      },
      userByFkCreator: staffUsers
        ?.filter(({ user }) => user.id === data.fk_creator)
        .map(({ user }) =>
          retrieveObjectMapper(user, ['firstname', 'lastname'])
        )?.[0],
      creator: staffUsers
        ?.filter(({ user }) => user.id === data.fk_creator)
        .map(({ staff, user }) => ({
          displayName: staff?.display_name,
          user: retrieveObjectMapper(user, ['firstname', 'lastname']),
        }))?.[0],
      eventPhotos: photos,
    },
  };
};

export const msFetchEventPhotos = async reqData => {
  const _classOpsService = classOpsService();
  const eventId = reqData?.IDEvent;
  const response = await _classOpsService.get(GET_EVENT_PHOTOS(eventId));
  return {
    data: response?.data?.data?.map(photo => ({
      ID: photo?.id,
      fileKey: photo?.file_key,
      eventPhotoPreSignedURL: photo?.file_key_presigned_url,
      fkEvent: photo?.fk_event,
      status: photo?.status,
      index: photo?.index,
      active: photo?.active,
    })),
    totalCount: response?.data?.totalCount,
  };
};

export const msFetchEventByID = async (_, reqBody = {}) => {
  const {
    reqData,
    dispatchfetchEventByID = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    const _classOpsService = classOpsService();
    const resp = await _classOpsService.get(
      GET_EVENT_BY_ID(get(reqData, 'IDEvent'))
    );
    const eventScopes = await _classOpsService
      .get(GET_EVENT_SCOPES_BY_IDS, { eventIds: resp?.data?.id })
      .then(retrieveListOrObject);
    const centreIds = uniq(
      eventScopes
        .map(({ fk_centre }) => fk_centre)
        .filter(fk_centre => fk_centre)
    );
    const classIds = uniq(
      eventScopes.map(({ fk_class }) => fk_class).filter(fk_class => fk_class)
    );
    const [centres, classes, staffUsers, eventPhotos] = await Promise.all([
      centreIds.length > 0
        ? await centreSerivce()
            .post(...GET_CENTRE_BY_IDS(centreIds))
            .then(retrieveListOrObject)
        : Promise.resolve([]),
      classIds.length > 0
        ? await classService()
            .post(...GET_CLASS_BY_IDS(classIds))
            .then(retrieveListOrObject)
        : Promise.resolve([]),
      await userService()
        .post(...GET_STAFF_USER_BY_USER_IDS([resp?.data?.fk_creator]))
        .then(response => {
          return Object.entries(response?.data?.data).map(
            entry => entry[1]?.data?.[0]
          );
        }),
      await msFetchEventPhotos({ IDEvent: resp?.data?.id }),
    ]);
    const levelIds = uniq(
      classes.map(({ fk_level }) => fk_level).filter(fk_level => fk_level)
    );
    let levels = [];
    if (levelIds.length > 0) {
      levels = await schoolService()
        .post(...GET_LEVEL_BY_IDS(levelIds))
        .then(retrieveListOrObject);
    }
    const result = transformEventByIdResponse(
      resp,
      eventScopes,
      centres,
      classes,
      levels,
      staffUsers,
      eventPhotos
    );

    dispatchfetchEventByID(dispatch, false, result);
    return result;
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return dispatchfetchEventByID(dispatch, false, null, { error: e.message });
  }
};

export const msCreateNewEvent = async (_, reqBody = {}) => {
  try {
    const body = {
      cancellation_date: get(reqBody, 'event.cancellationDate'),
      child_event_fee: get(reqBody, 'event.childEventFee'),
      description: get(reqBody, 'event.description'),
      event_image_key: get(reqBody, 'event.eventImageKey'),
      event_logo_key: get(reqBody, 'event.eventLogoKey'),
      type: get(reqBody, 'event.eventType'),
      from: get(reqBody, 'event.from'),
      guest_count_limit: get(reqBody, 'event.guestCountLimit'),
      guest_event_fee: get(reqBody, 'event.guestEventFee'),
      guests_allowed: get(reqBody, 'event.guestsAllowed'),
      guests_details_required: get(reqBody, 'event.guestsDetailsRequired'),
      location: get(reqBody, 'event.location'),
      parent_consent_required: get(reqBody, 'event.parentConsentRequired'),
      payment_required: get(reqBody, 'event.paymentRequired'),
      rsvp_deadline: get(reqBody, 'event.rsvpDeadline'),
      terms_and_conditions: get(reqBody, 'event.termsAndConditions'),
      title: get(reqBody, 'event.title'),
      to: get(reqBody, 'event.to'),
      centre_id: get(reqBody, 'IDCentre'),
      class_ids: get(reqBody, 'IDClasses', []),
    };
    let res;
    if (get(reqBody, 'IDEvent')) {
      res = await classOpsService().post(
        SUBMIT_DRAFT_EVENT(get(reqBody, 'IDEvent')),
        body
      );
    } else {
      res = await classOpsService().post(CREATE_EVENT, body);
    }

    return {
      data: {
        submitEvent: {
          ID: get(res, 'data.id'),
        },
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msCancelEvent = async (_, { reqData }) => {
  try {
    const eventId = reqData?.IDEvent;
    const remarks = reqData?.remarks || '';
    await classOpsService().put(CANCEL_EVENT(eventId), { remarks });

    return {
      data: {
        cancelEvent: true,
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msSaveNewDraftEvent = async (_, reqBody = {}) => {
  try {
    const body = {
      cancellation_date: get(reqBody, 'event.cancellationDate'),
      child_event_fee: get(reqBody, 'event.childEventFee'),
      description: get(reqBody, 'event.description'),
      event_image_key: get(reqBody, 'event.eventImageKey'),
      event_logo_key: get(reqBody, 'event.eventLogoKey'),
      type: get(reqBody, 'event.eventType'),
      from: get(reqBody, 'event.from'),
      guest_count_limit: get(reqBody, 'event.guestCountLimit'),
      guest_event_fee: get(reqBody, 'event.guestEventFee'),
      guests_allowed: get(reqBody, 'event.guestsAllowed'),
      guests_details_required: get(reqBody, 'event.guestsDetailsRequired'),
      location: get(reqBody, 'event.location'),
      parent_consent_required: get(reqBody, 'event.parentConsentRequired'),
      payment_required: get(reqBody, 'event.paymentRequired'),
      rsvp_deadline: get(reqBody, 'event.rsvpDeadline'),
      terms_and_conditions: get(reqBody, 'event.termsAndConditions'),
      title: get(reqBody, 'event.title'),
      to: get(reqBody, 'event.to'),
      centre_id: get(reqBody, 'IDCentre'),
      class_ids: get(reqBody, 'IDClasses', []),
    };

    const res = await classOpsService().post(CREATE_DRAFT_EVENT, body);
    return {
      data: {
        createOrSaveEventDraft: {
          ID: get(res, 'data.id'),
        },
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msFetchCurrentEventCategory = async (_, reqBody = {}) => {
  const {
    reqData,
    dispatchCurrentEventCategory = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    const { reqParams, type } = reqData;
    const eventType = lowerCase(type);
    const _classOpsService = classOpsService();
    const eventTypeMap = {
      1: 'centre_event',
      2: 'field_trip',
    };
    const fullApiUrl = status => {
      const queryParams = {
        centreIds: get(reqParams, 'fkCentre'),
        levelIds: get(reqParams, 'fkLevel'),
        classIds: get(reqParams, 'fkClass'),
        eventTypes: get(reqParams, 'eventType', []).map(
          num => eventTypeMap[num]
        ),
        page: get(reqParams, 'pagination.page', 1),
        perPage: get(reqParams, 'pagination.perPage', 10),
        ...retrieveSortForMS(get(reqParams, 'pagination.sort'), 'sort'),
      };
      if (status === 'draft') {
        return `${GET_DRAFT_EVENTS}?${new URLSearchParams(
          queryParams
        ).toString()}`;
      }
      queryParams.category = status;
      return `${GET_EVENTS}?${new URLSearchParams(queryParams).toString()}`;
    };
    const eventResp = await _classOpsService.get(fullApiUrl(eventType));
    const eventIds = uniq(get(eventResp, 'data.data', []).map(({ id }) => id));
    const [eventScopes, rsvpCounts] = await Promise.all([
      eventIds.length > 0
        ? _classOpsService
            .get(GET_EVENT_SCOPES_BY_IDS, { eventIds: eventIds.join(',') })
            .then(retrieveListOrObject)
        : Promise.resolve([]),
      eventIds.length > 0
        ? _classOpsService
            .get(GET_RSVP_COUNT_DETAILS_BY_IDS, {
              eventIds: eventIds.join(','),
            })
            .then(res => res.data || {})
        : Promise.resolve({}),
    ]);
    const centreIds = eventScopes
      .map(({ fk_centre }) => fk_centre)
      .filter(fk_centre => fk_centre);
    const fkCentreIds = compact(uniq(centreIds));

    const classIds = eventScopes
      .map(({ fk_class }) => fk_class)
      .filter(fk_class => fk_class);
    const fkClassIds = compact(uniq(classIds));

    const userIds = get(eventResp, 'data.data', [])
      .map(({ fk_creator }) => fk_creator)
      .filter(fk_creator => fk_creator);
    const fkUserIds = compact(uniq(userIds));
    const [centres, classes, staffUsers] = await Promise.all([
      fkCentreIds
        ? await centreSerivce()
            .post(...GET_CENTRE_BY_IDS(fkCentreIds))
            .then(retrieveListOrObject)
        : Promise.resolve([]),
      fkClassIds
        ? await classService()
            .post(...GET_CLASS_BY_IDS(fkClassIds))
            .then(retrieveListOrObject)
        : Promise.resolve([]),
      fkUserIds
        ? await userService()
            .post(...GET_STAFF_USER_BY_USER_IDS(fkUserIds))
            .then(response => {
              return Object.entries(response?.data?.data).map(
                entry => entry[1]?.data?.[0]
              );
            })
        : Promise.resolve([]),
    ]);
    const levelIds = classes
      .map(({ fk_level }) => fk_level)
      .filter(fk_level => fk_level);
    const fkLevelIds = compact(uniq(levelIds));

    let levels = [];
    if (fkLevelIds) {
      levels = await schoolService()
        .post(...GET_LEVEL_BY_IDS(fkLevelIds))
        .then(retrieveListOrObject);
    }
    const transformedEventCategoryResp = transformEventResponse(
      eventResp,
      eventScopes,
      centres,
      classes,
      levels,
      staffUsers,
      rsvpCounts
    );

    dispatchCurrentEventCategory(dispatch, false, transformedEventCategoryResp);
    return transformedEventCategoryResp;
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return dispatchCurrentEventCategory(dispatch, false, null, {
      error: e.message,
    });
  }
};

export const msExportRsvpList = async (_, reqBody = {}) => {
  const {
    reqData,
    dispatchExportRsvpList = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    const _classOpsService = classOpsService();
    const response = await _classOpsService.get(
      EXPORT_RSVP_LIST(reqData?.IDEvent)
    );
    const result = {
      data: {
        exportRsvpList: response?.data?.url,
      },
      success: true,
    };
    dispatchExportRsvpList(dispatch, false, result);
    return result;
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return dispatchExportRsvpList(dispatch, false, null, {
      error: e.message,
    });
  }
};

export const msGetEventPhotoAlbumUploadUrl = async (_, reqBody = {}) => {
  const { reqData } = reqBody;
  try {
    const _classOpsService = classOpsService();
    const eventId = reqData?.IDEvent;
    let file = reqData?.file;
    if (file) {
      file = {
        file_name: file?.fileName,
        mime_type: file?.mimeType,
        file_path: file?.filePath,
        file_size: file?.fileSize,
      };
    }
    const response = await _classOpsService.post(
      GET_EVENT_PHOTO_ALBLUM_UPLOAD_URL(eventId),
      file
    );

    return {
      data: {
        getEventPhotoAlbumUploadUrl: {
          Url: response.data?.url,
          Key: response.data?.key,
          Filename: response.data?.filename,
          FileSize: file?.fileSize,
        },
      },
      success: true,
    };
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return e;
  }
};

export const msGetEventPhotoAlbumUploadUrlForSchool = async (
  _,
  { reqData }
) => {
  try {
    const _classOpsService = classOpsService();
    let file = reqData?.file;
    if (file) {
      file = {
        file_name: file?.fileName,
        mime_type: file?.mimeType,
        file_path: file?.filePath,
        file_size: file?.fileSize,
      };
    }
    const response = await _classOpsService.post(
      GET_EVENT_UPLOAD_URL_FOR_SCHOOL,
      file
    );

    return {
      data: {
        getEventPhotoUploadUrl: {
          Url: response.data?.url,
          Key: response.data?.key,
          Filename: response.data?.filename,
          FileSize: file?.fileSize,
        },
      },
      success: true,
    };
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return {
      data: null,
      error: [
        {
          extensions: {
            errorCode: get(e, 'response.data.errCode'),
            level: 'warn',
          },
          message: get(e, 'response.data.error'),
          path: ['getEventPhotoUploadUrl'],
        },
      ],
    };
  }
};

export const msAddEventPhotos = async (_, reqBody = {}) => {
  const { reqData } = reqBody;
  try {
    const _classOpsService = classOpsService();
    const eventId = reqData?.IDEvent;
    const fileKeys = reqData?.fileKeys;
    await _classOpsService.post(ADD_EVENT_PHOTOS(eventId), fileKeys);

    return {
      data: {
        addEventPhotos: true,
      },
      success: true,
    };
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return e;
  }
};

export const msUpdateEventMediaIndex = async (_, reqBody = {}) => {
  const { reqData } = reqBody;
  try {
    const _classOpsService = classOpsService();
    const eventId = reqData?.IDEvent;
    await _classOpsService.put(UPDATE_EVENT_PHOTOS_INDEX(eventId), {
      index_list: reqData?.eventPhotoIdAndIndex?.map(({ ID, Index }) => ({
        id: ID,
        index: Index,
      })),
    });

    return {
      data: {
        updateIndexForEventPhotos: true,
      },
      success: true,
    };
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return e;
  }
};

export const msDeleteEventPhotos = async (_, reqBody = {}) => {
  const { reqData } = reqBody;
  try {
    const _classOpsService = classOpsService();
    const eventId = reqData?.IDEvent;
    const photoIds = reqData?.IDPhotos;
    await _classOpsService.delete(DELETE_EVENT_PHOTOS(eventId), {
      params: {
        photoIds: photoIds.join(','),
      },
    });

    return {
      data: {
        deleteEventPhotos: true,
      },
      success: true,
    };
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return e;
  }
};

export const msGetEventTypes = async (
  _,
  { searchCategory, dispatchCategoryFetch, dispatch }
) => {
  dispatchCategoryFetch(dispatch, true, { data: null, type: searchCategory });
  try {
    const _classOpsService = classOpsService();
    const response = await _classOpsService.get(GET_EVENT_TYPES).then(resp => {
      const respData = {
        data: {
          data: get(resp, 'data.types'),
        },
      };
      return {
        totalCount: get(resp, 'data.types.length', 0),
        data: retrieveListOrObject(respData),
      };
    });

    const result = {
      findAllConfigByCategory: {
        data: get(response, 'data', []).map(eachResp => ({
          ...retrieveObjectMapper(eachResp, ['ID:id', 'label', 'description']),
        })),
      },
    };
    dispatchCategoryFetch(dispatch, false, {
      data: result,
      type: searchCategory,
    });
    return {
      data: result,
      success: true,
    };
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    dispatchCategoryFetch(
      dispatch,
      false,
      { data: null, type: searchCategory },
      [e?.message]
    );
    return e;
  }
};

export const msUpdateEventDetails = async (_, reqBody = {}) => {
  const { reqData } = reqBody;
  try {
    const body = {
      cancellation_date: get(reqData, 'event.cancellationDate'),
      child_event_fee: get(reqData, 'event.childEventFee'),
      description: get(reqData, 'event.description'),
      event_image_key: get(reqData, 'event.eventImageKey'),
      event_logo_key: get(reqData, 'event.eventLogoKey'),
      type: get(reqData, 'event.eventType'),
      from: get(reqData, 'event.from'),
      guest_count_limit: get(reqData, 'event.guestCountLimit'),
      guest_event_fee: get(reqData, 'event.guestEventFee'),
      guests_allowed: get(reqData, 'event.guestsAllowed'),
      guests_details_required: get(reqData, 'event.guestsDetailsRequired'),
      location: get(reqData, 'event.location'),
      parent_consent_required: get(reqData, 'event.parentConsentRequired'),
      payment_required: get(reqData, 'event.paymentRequired'),
      rsvp_deadline: get(reqData, 'event.rsvpDeadline'),
      terms_and_conditions: get(reqData, 'event.termsAndConditions'),
      title: get(reqData, 'event.title'),
      to: get(reqData, 'event.to'),
      centre_id: get(reqData, 'IDCentre'),
      class_ids: get(reqData, 'IDClasses', []),
    };
    const eventId = get(reqData, 'IDEvent');
    const res = await classOpsService().put(UPDATE_EVENT(eventId), body);

    return {
      data: {
        updateEventDetails: {
          ID: res?.data?.id,
        },
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      error: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msDeleteEventDraft = async (_, { IDEvent }) => {
  try {
    await classOpsService().delete(DELETE_DRAFT_EVENT(IDEvent));
    return {
      data: {
        deleteEventDraft: true,
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msPublishEvent = async (_, event) => {
  try {
    const res = await classOpsService().put(
      PUBLISH_EVENT(get(event, 'IDEvent'))
    );
    return {
      data: {
        publishEvent: {
          ID: res?.data?.id,
        },
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msFetchRsvpCount = async (
  dispatch,
  { IDEvent: event, dispatchgetRsvpEvents }
) => {
  dispatchgetRsvpEvents(dispatch);
  try {
    const data = await classOpsService()
      .get(GET_RSVP_COUNT_DETAILS_BY_IDS, {
        eventIds: parseInt(get(event, 'IDEvent'), 10),
      })
      .then(res => res.data || {});

    const result = {
      getRsvpStatusCountDetails: {
        CountMapping: data[get(event, 'IDEvent')],
      },
    };
    dispatchgetRsvpEvents(dispatch, false, result);
    return result;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const transformEventRSVPData = (
  data,
  childData,
  userData,
  currentChildClasses,
  classes
) => {
  const rsvpData = get(data, 'data', {});
  const transformedData = {
    getEventRsvpChildList: {
      totalCount: get(rsvpData, 'totalCount', 0),
      data: get(rsvpData, 'data', []).map(dt => ({
        ...retrieveObjectMapper(get(dt, 'event_rsvp'), [
          'ID:id',
          'rsvp_ack_fk_user',
          'rsvp_remove_fk_user',
        ]),
        userByRsvpAckFkUser: (() => {
          const rsvpUser = userData?.filter(
            ({ id }) => id === get(dt, 'event_rsvp.rsvp_ack_fk_user')
          );
          if (!rsvpUser || !get(rsvpUser, 'length')) return null;
          return retrieveObjectMapper(rsvpUser[0], [
            'ID:id',
            'firstname',
            'lastname',
          ]);
        })(),
        userByRsvpRemoveFkUser: (() => {
          const rsvpRemoveUser = userData?.filter(
            ({ id }) => id === get(dt, 'event_rsvp.rsvp_remove_fk_user')
          );
          if (!rsvpRemoveUser || !get(rsvpRemoveUser, 'length')) return null;
          return retrieveObjectMapper(rsvpRemoveUser[0], [
            'ID:id',
            'firstname',
            'lastname',
          ]);
        })(),
        eventRsvpGuests: (() => {
          const guestData = get(dt, 'event_rsvp_guests', {});
          return {
            totalCount: get(guestData, 'totalCount', 0),
            data: get(guestData, 'data', []).map(gdt => ({
              ...retrieveObjectMapper(gdt, [
                'ID:id',
                'name',
                'mobile_phone',
                'fk_relation',
              ]),
            })),
          };
        })(),
        child: (() => {
          const rsvpChild = childData?.filter(
            ({ id }) => id === get(dt, 'event_rsvp.fk_child')
          );
          if (!rsvpChild || !get(rsvpChild, 'length')) return null;
          return {
            ...retrieveObjectMapper(rsvpChild[0], [
              'ID:id',
              'firstname',
              'lastname',
            ]),
            currentClass: (() => {
              const childClass = currentChildClasses?.filter(
                ({ fk_child }) => fk_child === get(dt, 'event_rsvp.fk_child')
              );
              if (!childClass || !get(childClass, 'length')) return null;
              const currentClass = childClass?.filter(
                ({ id }) => id === get(childClass[0], 'id')
              );
              const mappedClass = classes?.filter(
                ({ id }) => id === get(currentClass[0], 'fk_class')
              );
              return {
                class: {
                  label: get(mappedClass[0], 'label'),
                },
              };
            })(),
          };
        })(),
      })),
    },
    // update this with new ms endpoint
    getRsvpAttendeesClassAndCentreCountByStatus: {
      CentreCount: 1,
      ChildCount: 1,
      ClassCount: 1,
      GuestCount: 1,
    },
  };
  return transformedData;
};

export const msFetchRsvpData = async (
  dispatch,
  { reqData, dispatchRsvpData }
) => {
  dispatchRsvpData(dispatch);
  try {
    const _classOpsService = classOpsService();
    const _childService = childService();
    const _classService = classService();

    const res = await _classOpsService.get(
      GET_EVENT_RSVPS(get(reqData, 'IDEvent')),
      {
        eventId: get(reqData, 'IDEvent'),
        status: get(reqData, 'filter'),
      }
    );
    const childIds = get(res, 'data.data', []).map(({ event_rsvp }) =>
      get(event_rsvp, 'fk_child')
    );
    const fkChildIds = compact(uniq(childIds));
    const rsvpAckFkUsers = get(res, 'data.data', []).map(({ event_rsvp }) =>
      get(event_rsvp, 'rsvp_ack_fk_user')
    );
    const rsvpRemoveFkUsers = get(res, 'data.data', []).map(({ event_rsvp }) =>
      get(event_rsvp, 'rsvp_remove_fk_user')
    );
    const allRsvpFkUserIds = uniq(
      compact(concat(rsvpAckFkUsers, rsvpRemoveFkUsers))
    );

    const [childData, userData, childClasses] = await Promise.all([
      fkChildIds
        ? _childService
            .post(...GET_CHILD_BY_IDS(fkChildIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      allRsvpFkUserIds
        ? userService()
            .post(...GET_BY_IDS(allRsvpFkUserIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      fkChildIds
        ? _childService
            .post(
              ...GET_CHILD_CLASSES_BY_CHILD_IDS(
                fkChildIds,
                {
                  sort: '-id',
                },
                { active: [{ eq: [true, false] }] }
              )
            )
            .then(retrieveListOrObject)
            .catch(() => ({}))
        : Promise.resolve([]),
    ]);

    const classIds = childClasses.map(f => f?.fk_class);
    const fkClassIds = compact(uniq(classIds));

    const classes = await (fkClassIds
      ? _classService
          .post(...GET_CLASS_BY_IDS(fkClassIds))
          .then(retrieveListOrObject)
          .catch(() => [])
      : Promise.resolve([]));

    const result = transformEventRSVPData(
      res,
      childData,
      userData,
      childClasses,
      classes
    );
    dispatchRsvpData(dispatch, false, result);
    return result;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msRemoveChildFromRsvp = async (_, reqData) => {
  try {
    await classOpsService().delete(
      REMOVE_CHILD_FROM_RSVP_LIST(
        get(reqData, 'IDEvent'),
        get(reqData, 'IDEventRsvp')
      )
    );
    return {
      data: {
        removeChildFromRsvpList: true,
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msRemoveRsvpGuest = async (_, reqData) => {
  try {
    await classOpsService().delete(
      REMOVE_RSVP_GUESTS(
        get(reqData, 'IDEvent'),
        get(reqData, 'IDEventRsvp'),
        reqData?.IDEventRsvpGuest ? [reqData?.IDEventRsvpGuest] : []
      )
    );
    return {
      data: {
        removeEventRsvpGuest: true,
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msAddChildToRsvp = async (_, reqData) => {
  try {
    await classOpsService().post(ADD_EVENT_RSVP(get(reqData, 'IDEvent')), {
      child_id: get(reqData, 'IDChild'),
    });
    return {
      data: {
        addChildIntoRsvpList: true,
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export default {
  msFetchEventListing,
  msFetchEventByID,
  msCreateNewEvent,
  msSaveNewDraftEvent,
  msFetchCurrentEventCategory,
  msUpdateEventDetails,
  msGetEventPhotoAlbumUploadUrl,
  transformEventResponse,
  msPublishEvent,
  msFetchRsvpCount,
  msFetchRsvpData,
  msRemoveChildFromRsvp,
  msAddChildToRsvp,
};
